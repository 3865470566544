import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import leftIcon from "../../assests/icons/startIcon.png";
import rightIcon from "../../assests/icons/endIcon.png";
import play from "../../assests/icons/Play.png";
import premiumLock from "../../assests/icons/premiumLock.png";

import BreathingText from "../../assests/icons/lungicon.png";
import Loader from "./Loader";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import PremiumExerciseCard from "./PremiumExerciseCard";
import RegularExerciseCard from "./RegularExerciseCard";
import SeoHelmet from "../../utils/SeoHelmet";

const BreathingExerciseCard = ({ data }) => {
  const [filterData, setFilteredData] = useState([]);
  const [exerciseCategory, setExerciseCategory] = useState("");
  const [cancelText,setCancelText]=useState('');
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exerciseData, setExerciseData] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userIsPremium, setUserIsPremium] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (data) {
  //     setIsLoading(true);
  //     const guidedBreathingData = data.find(item => item.exercise_category === "GUIDED BREATHING");
  //     const notes = data.find(item => item.title === "notes");
  //     setNotes(notes?.data?.[0]);
  //     if (guidedBreathingData) {
  //       setExerciseCategory(guidedBreathingData.exercise_category);
  //       const filteredData = guidedBreathingData.data.filter(item => !item.is_premium);
  //       setFilteredData(filteredData);
  //     }
  //     setIsLoading(false);
  //   }
  // }, [data]);
  // useEffect(() => {
  //   if (data) {
  //     setIsLoading(true);
  //     setError(null);
  //     try {
  //       const guidedBreathingData = data.find(item => item.exercise_category === "GUIDED BREATHING");
  //       const notes = data.find(item => item.title === "notes");
  //       setNotes(notes?.data?.[0]);
  //       if (guidedBreathingData) {
  //         setExerciseCategory(guidedBreathingData.exercise_category);
  //         const filteredData = guidedBreathingData.data;
  //         setFilteredData(filteredData);
  //       } else {
  //         throw new Error("Guided breathing data not found");
  //       }
  //     } catch (err) {
  //       setError("Failed to load exercise data. Please try again later.");
  //       console.error("Error processing data:", err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // }, [data]);
  useEffect(() => {
    // Check if user is logged in
    const accessToken = localStorage.getItem("accesstoken");
    setIsLoggedIn(!!accessToken);

    // Rest of your useEffect logic...
    if (data) {
      setIsLoading(true);
      setError(null);
      try {
        const mainHeaderObject = data.find(item => item.title === "mainHeader");
        if (mainHeaderObject) {
          setUserIsPremium(mainHeaderObject.data.user_is_premium);
        }

        const exerciseCategories = data.filter(
          (item) => item.title === "ExerciseList"
        );

        const categorizedExercises = exerciseCategories.reduce(
          (acc, category) => {
            acc[category.exercise_category] = category.data;
            return acc;
          },
          {}
        );

        setExerciseData(categorizedExercises);

        const notesData = data.find((item) => item.title === "notes");
        setNotes(notesData?.data?.[0]);
        const cancelText = data.find((item) => item.title === "cancelNotes");
        setCancelText(cancelText?.data?.[0])
      } catch (err) {
        setError("Failed to load exercise data. Please try again later.");
        console.error("Error processing data:", err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [data]);
  // useEffect(() => {
  //   const mainHeaderObject = data?.find(item => item.title === "mainHeader");
    
  //   if (mainHeaderObject) {
      
      
  //     // Accessing the user_is_premium property from the data
  //     const userIsPremium = mainHeaderObject.data.user_is_premium;
  //     setUserIsPremium(userIsPremium);
  
  //     // You can also set other states based on the mainHeader data if needed
  //     // For example:
  //     // setExerciseOfTheDay(mainHeaderObject.data.exercise_of_the_day);
  //     // setExerciseDescription(mainHeaderObject.data.exercise_description);
  //   } else {
  //     console.log("mainHeader not found");
  //   }
  // }, [data]);
  // useEffect(() => {
  //   if (data) {
  //     setIsLoading(true);
  //     setError(null);
  //     try {
  //       // Filter out the 'notes' and 'cancelNotes' items
  //       const exerciseCategories = data.filter(
  //         (item) => item.title === "ExerciseList"
  //       );

  //       // Create an object with exercise categories as keys
  //       const categorizedExercises = exerciseCategories.reduce(
  //         (acc, category) => {
  //           acc[category.exercise_category] = category.data;
  //           return acc;
  //         },
  //         {}
  //       );

  //       setExerciseData(categorizedExercises);

  //       // Set notes
  //       const notesData = data.find((item) => item.title === "notes");
  //       setNotes(notesData?.data?.[0]);
  //       const cancelText = data.find((item) => item.title === "cancelNotes");
  //       setCancelText(cancelText?.data?.[0])
  //     } catch (err) {
  //       setError("Failed to load exercise data. Please try again later.");
  //       console.error("Error processing data:", err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // }, [data]);

  useEffect(() => {
   sendEventsToAnalyticsSystems("exercise_listing_screen");
  }, [])
  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }
  const handleExerciseClick = (exercise) => {
    sendEventsToAnalyticsSystems("exercise_listing_tap", {
      exerciseName: exercise.exercise_enum,
      // exerciseNumber: index + 1,
      exerciseType: exercise.exercise_type,
    });
  };

  const updateCompletedExerciseCount = () => {
    const count = parseInt(
      localStorage.getItem("completedExerciseCount") || "0"
    );
    localStorage.setItem("completedExerciseCount", (count + 1).toString());
  };
  if (isLoading) {
    return <Loader />; // Show loader while loading
  }
  return (
    <>
      <SeoHelmet
      title="Best Breathing Exercises for stress, sleep and lung health management"
      description="Discover top breathing exercises for anxiety relief and stress reduction. Simple techniques to boost relaxation and lung health. Start your journey today!"
    />
      {/* <div className="flex justify-center mt-[3rem] min-h-screen">
      <div className="w-full max-w-3xl px-4 py-8">
        <div className='flex justify-center items-center gap-5 '>
          <img src={BreathingText} alt="Breathing Icon" />
          <p className='text-[26px] font-semibold'>Breathing Exercise</p>
        </div>
        <div className="text-center flex justify-center items-center gap-1 px-4 mt-8 mb-8">
          <img className="h-full" src={leftIcon} alt="Left Icon" />
          <p className="text-xs text-[#9E9E9E] font-medium">
            {exerciseCategory}
          </p>
          <img className="h-full" src={rightIcon} alt="Right Icon" />
        </div>
        <div className="mt-5">
        {filterData.map((item, i) => (
          <Link
            key={i}
            to={`/exercise-details-page/${item.exercise_enum}`}
            state={{ exercise: item }}
            onClick={() => handleExerciseClick(item)}
          >
            <div className="flex flex-col justify-center mb-4">
              <div 
              className="w-full bg-white rounded-lg border-[1px] border-[#E8E8E8]"
              className={`${item.is_premium?"border-border-premium bg-backgroung_premium":"border-[#E8E8E8] bg-white"} w-full  rounded-lg border-[1px]`}
              >
                <div className="flex items-center justify-between rounded-lg h-[100px]">
                  <img
                    src={`${process.env.REACT_APP_S3_BUCKET_URL}/${item.listing_image_url}`}
                    alt={item.title}
                    className="w-24 h-[100px] object-cover rounded"
                  />
                  <div className="flex flex-col flex-grow ml-4 gap-y-6">
                    <div>
                      <p className="text-xs text-[#212121]">{item.title}</p>
                      <p className="text-sm font-semibold text-[#171717]">
                        {item.exercise_description}
                      </p>
                    </div>
                    <p className="text-[10px] font-semibold text-[#212121]">
                      {item.exercise_time}
                    </p>
                  </div>
                  <button 
                  // className="bg-button-bg-color mr-2 text-white p-3 rounded-full"
                  className={` ${item.is_premium ? 'bg-button-lock-bg-color':'bg-button-bg-color' } mr-2 text-white p-3 rounded-full`}
                  >
                    {item.is_premium ?<img src={premiumLock} alt="Play" />:<img src={play}/>}
                  </button>
                </div>
              </div>
            </div>
          </Link>
        ))}
        </div>
        <div className="mt-5 bg-[#F6F6F6] rounded-lg p-3">
          <div className="px-3">
            <p className="text-xs font-medium">{notes.note_title}</p>
            <p className="mt-1 text-[10px] font-normal">{notes.note_description}</p>
          </div>
        </div>
      </div>
    </div> */}
      <div className="flex justify-center mt-[3rem] min-h-screen">
        <div className="w-full max-w-3xl px-4 py-8">
          <div className="flex justify-center items-center gap-5 ">
            <img src={BreathingText} alt="Breathing Icon" />
            <p className="text-[26px] font-semibold">Breathing Exercise</p>
          </div>
          <div className="mt-5">
            {Object.entries(exerciseData).map(([category, exercises]) => (
              <div key={category}>
                <div className="text-center flex justify-center items-center gap-1 px-4 mt-8 mb-8">
                  <img className="h-full" src={leftIcon} alt="Left Icon" />
                  <p className="text-xs text-[#9E9E9E] font-medium">
                    {category}
                  </p>
                  <img className="h-full" src={rightIcon} alt="Right Icon" />
                </div>
                {exercises.map((item, i) =>
                   !item.is_premium ? (
                    <RegularExerciseCard
                      key={i}
                      item={item}
                      handleExerciseClick={handleExerciseClick}
                    />
                  ) : (
                    <PremiumExerciseCard
                      key={i}
                      item={item}
                      handleExerciseClick={handleExerciseClick}
                      userIsPremium={userIsPremium}
                      isLoggedIn={isLoggedIn}
                    />
                  )
                )}
              </div>
            ))}
          </div>
          {notes && (
            <div className="mt-5 bg-[#F6F6F6] rounded-lg p-3">
              <div className="px-3">
                <p className="text-xs font-medium">{notes.note_title}</p>
                <p className="mt-1 text-[10px] font-normal">
                  {notes.note_description}
                </p>
              </div>
            </div>
          )}
          {cancelText && (
            <div className="mt-5">
              <div className="px-3">
                <p className="mt-1 text-[10px] font-medium">
                  {cancelText.note_title} {cancelText.cancel_email}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BreathingExerciseCard;
